//
// Footer Styles
// --------------------------------------------------

#footer {
	border-top: 3px solid rgba(10, 84, 123, 0.1);
	color: #ffffff;
	padding: 0;

	.bg {
		background: linear-gradient(90deg, white 60%, $color-primary 33%);
		padding-top: 60px;
		padding-bottom: 40px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		margin-right: -30px;

		@media (max-width: 530px) {
			margin-right: 0;
			flex-direction: column;
		}

		li {
			margin-bottom: 5px;
			margin-right: 30px;
			width: calc(50% - 30px);

			a {
				color: $color-gray;
				border-bottom: 1px solid rgba(0, 31, 48, 0.1);
				padding: 15px 0;
				width: 100%;
				display: inline-block;
			}

			&:last-child,
			&:nth-last-child(2) {

				a {
					border-bottom: none;
				}
			}
		}
	}


	#footer-bottom {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: rgba(10, 84, 123, 0.1);
		color: $color-primary;
		padding: 5px 0;

		.container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
		}
	}

	.powered {
		position: relative;
		color: inherit;

		&:hover {
			color: inherit;
			text-decoration: none;

			#logo-alt-footer-trois {
				fill: $color-primary;
				transform: scale(1.2);
			}

			#logo-alt-footer-deux {
				fill: $color-primary;
				transform: scale(1.2);
			}

			#logo-alt-footer-un {
				fill: $color-primary;
				transform: scale(1.2);
			}

			&:after {
				width: 90px;
			}
		}

		svg {
			overflow: visible;
			width: 50px;
			height: 24px;
			margin: 0 0 0 5px;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: 34px;

			#logo-alt-footer-trois {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
				transform: scale(1);
				transform-origin: center;
			}

			#logo-alt-footer-deux {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
				transform: scale(1);
				transform-origin: center;
			}

			#logo-alt-footer-un {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
				transform: scale(1);
				transform-origin: center;
			}
		}

		&:after {
			display: block;
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 0px;
			height: 2px;
			background-color: $color-primary;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
}