//
// Header Styles
// --------------------------------------------------

#button-form {
	z-index: 1;
	position: fixed;
	top: 45%;
	right: -119px;
	background-color: $color-primary;
	color: white;
	padding-top: 10px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 40px;
	border-radius: 12px;
	transform: rotate(-90deg);
	font-size: 20px;

	&:hover {
		background-color: #0C405C;

		i {
			color: white;
		}
	}
}

.page-header.no-image {
    display: none;
}


#header {
	width: 100%;
	background-color: white;
	padding: 10px 0;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;
	margin-bottom: 80px;

	&.sticky {
		padding: 5px 0;

		#logo {
			width: 80px;
		}

		#header-extras {
			opacity: 0;
			margin-top: -40px;
		}

		#menu {
			margin-top: 30px;
		}
	}


	#menu-mobile-trigger {
		font-size: 31px;
		display: none;

		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: white;

			&:not(:first-child) {
				margin-top: 6px;
			}
		}
	}

	#logo {
		float: left;
		width: 110px;
		max-width: 100%;

		a {
			display: block;
		}
	}

	#search-action {
		.input-group {
			display: flex;

			.form-control {
				width: 250px;
			}
		}
	}

	#menu {
		transition: all 0.4s linear;
		margin-top: 60px;
		float: right;

		#menu-main-container {
			display: inline-block;
			width: 100%;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;

				li {
					float: left;
					position: relative;
					margin-right: 60px;

					a {
						font-size: 16px;
						display: block;
						color: $color-gray;
						text-transform: uppercase;
						margin-left: 0;

						&:hover {
							color: $color-primary;
						}

						&:active {
							color: $color-primary;
							font-weight: bolder;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						>a {
							color: $color-primary;
							font-weight: bold;
						}

						.sub-menu {
							li {
								a {
									color: white;
								}
							}
						}
					}

					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: 0;
						transform: translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;

						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							padding: 0 15px;
							background-color: $color-primary;
							transition: $transition-base;

							a {
								display: block;
								color: white;
								padding: 15px 5px;
								white-space: nowrap;
								min-width: 400px;
								font-size: 14px;
								border-bottom: 0.5px solid $color-primary-light;
							}

							&:hover {
								background-color: darken($color-primary, 6%);

								a {
									color: white;
								}
							}

							&:last-child {
								margin-bottom: 0;

								a {
									border-bottom: none;
								}
							}

							&.current-menu-item {
								background-color: darken($color-primary, 6%);

								a {
									color: white;
									font-weight: 400;
								}
							}
						}
					}

					&.menu-item-has-children {
						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f105';
							display: block;
							position: absolute;
							top: 50%;
							right: -12px;
							font-weight: 100;
							transform: translateY(-50%);
							transform-origin: center;
							color: white;
							transition: transform 0.25s;
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(90deg);
							}

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: translateY(0);
								padding-top: 15px;
							}
						}
					}
				}
			}
		}
	}

	#header-extras {
		position: absolute;
		top: 0;
		right: 15px;
		transition: all 0.4s linear;
		margin-top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;


		.extra {
			display: inline-block;
			margin-right: 25px;

			&:last-child {
				margin-right: 0;
			}
		}

		#header-cart {
			i {
				color: #ffffff;
				margin-right: 6px;
			}
		}

		#language-switcher {
			padding: 0;
			margin-top: 0;
			margin-bottom: 0;
			list-style: none;
			font-size: 14px;
			color: $color-primary;
			position: relative;
			background-color: white;

			&:hover {

				.lang-menu {
					height: auto;
					opacity: 1;
				}

				.lang {

					i {
						transform: rotate(180deg);
					}
				}
			}

			.lang-menu {
				opacity: 0;
				height: 0;
				transition: $transition-base;
				position: absolute;
				left: 0;
				padding-top: 5px;
			}

			.lang {
				text-transform: uppercase;

				&:hover {
					color: $color-link-hover;
				}

				&.active {
					cursor: pointer;

					i {
						margin-left: 4px;
					}
				}

				i {
					transform: rotate(0deg);
					transition: $transition-base;
				}
			}
		}

		.btn-primary {
			font-size: 14px;
			padding: 2px 10px;
			min-width: 50px;

			i {
				font-size: 18px;
			}
		}
	}
}


.page-header {

	width: 100%;
	max-width: 2500px;
	margin: 0 auto;

	.img {
		width: 100%;
		max-width: 1800px;
		height: 100vh;
		max-height: 650px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		margin: auto;

		@media (max-width:991px) {
			max-height: 50vh;
		}
	}

	.page-header-slider {

		.img {
			height: 70vh;
			max-height: 880px;
		}
	}


	.slick-dots {
		display: flex;
		justify-content: center;

		li {
			button {
				background-color: $color-primary !important;
				width: 10px !important;
				height: 10px !important;
			}
		}

	}

	.slick-next {
		&::before {
			color: $color-primary;
			content: "\f178";
			font-size: 16px;
			font-weight: normal;

		}
	}

	.slick-prev {
		&::before {
			color: $color-primary;
			content: "\f177";
			font-size: 16px;
			font-weight: normal;
		}
	}

}