//
// Responsive Styles
// Checker les tailles: 1199px, 991px, 767px, 576px, 350px
// --------------------------------------------------

@media (max-width:1441px){
	#header #menu #menu-main-container ul li {
		margin-right: 30px;
	}
}

@media (min-width: 1200px) {

	footer  .maxillo-infos {
		padding-left: 5% !important;
	}

	.container {
		max-width: 1170px;
	}

	.partners {
		.col-xl-2 {
			max-width: 11%;
		}
	}
}

@media (min-width: 1441px) {

	.container {
		max-width: 1440px;
	}
}

@media (max-width: 1199px) {
	#page-default #gform_wrapper_3 {

		#field_3_32 #input_3_32 {
			display: grid;
			grid-template-columns: repeat(8, 1fr);
		}

		#field_3_44 #input_3_44 {
			display: grid;
			grid-template-columns: repeat(8, 1fr);
		}
	}
	
	.logo-partners {
		flex-wrap: wrap !important;
	}

	#post-content .gchoice {
		width: 33% !important;
	}

	.infos-contact .btn-primary{
		padding: 4px 14px;
	}

	#post-content {
		.gchoice_3_10_0{
			width: 100% !important;
		}

		.gchoice_3_10_1 {
			width: 100% !important;
		}
}

	#header #menu-mobile-trigger{
		display: flex;
		flex-direction: column;

		.trigger-bar {
			background-color: #000 !important;
		}
	}
	footer ul li {
		width: calc(50% - -49px) !important;
	}

}

@media (max-width: 991px) {

	.tdu-popup-content {
		overflow-y: scroll !important;
		padding: 15px;
		width: 95vw !important;

		.tdu-popup-top{
			top: 0;
			right: -320px;
		}

		.tdu-popup-inner-content {
			gap: 0;
			padding: 0;
			max-height: 500px;

			img {
				max-width: 40%;
			}

			.tdu-popup-button {
				a {
					margin-bottom: 15px;
				}
			}
		}
	}

	#page-default #gform_wrapper_3 {

		#field_3_32 #input_3_32 {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			img {
				height: 100%;
				max-width: 100%;
			}
			label::before {
				top: 32px !important;
			}
		}

		#field_3_44 #input_3_44 {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			img {
				height: 100%;
				max-width: 100%;
			}
			label::before {
				top: 32px !important;
			}
		}
	}

	#post-content .gchoice {
		width: 50% !important;
	}

	#post-content {
		.gchoice_3_10_0{
			width: 100% !important;
		}

		.gchoice_3_10_1 {
			width: 100% !important;
		}
}

	#page-about .infos .row{

		display: flex;
		justify-content: center;

		.cta-faq a{
			margin-left: 0;
		}
		.cta-faq h3 {
			margin-left: 0;
		}
	}

	footer .infos-contact{
		padding-left: 0!important;
	}

	
}

@media (max-width: 767px) {

	.form-content #gform_fields_2 {
		display: flex;
		flex-direction: column;
	}

	#page-default #gform_wrapper_3 #field_3_13 .ginput_container {
		width: calc(100%);
	}

	#post-content {
		.offset-1 {
			padding: 0 15px 0 15px;
			margin-left: 0 !important;
		}
		.col-10 {
			max-width: 100% !important;
			flex: 0 0 100% !important;
		}

		#gform_fields_3 {
			display: flex !important;
			flex-direction: column !important;
		}
	}

	#page-default #input_3_39 {
		width: calc(100% - 12px);
	}

	#page-informations .container .row {

		max-width: 100% ;
		margin-right: 0 !important;
		margin-left: 0 !important;

		.maxillo-infos-card a{
			justify-content: center;
		}
		.info-image {
			max-width: 100%;
			flex: 0 0 100%;
		}

		.col-sm-6{
			max-width: 100% !important;
			flex: 0 0 100% !important;
		}

		.col-8 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	#page-homepage {
		.about .gallery-slider {
			margin: 0 7.5px;
			margin-top: 15px;
		}
	}

	form .gform_fields .uploader-btn {
		flex-wrap: wrap;
	}

	#post-content {

		.gchoice {
			width: 100% !important;
		}

		.uploader-btn button {
			margin-top: 4% !important;
			margin-bottom: 4% !important;
		}
		
	}

	#page-informations .container .row {
		margin-left: -95px;
		margin-right: -71px;
		justify-content: center;
	}

	#header #logo {
		a img {
			margin-left: -33%;
		}
	}

	#main-content .infos {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#page-contact #contact-map {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-right: 0 !important;
		max-width: 60.33333%;
	}

	#page-contact  {	
		.maxillo-infos {
			display: block !important;
			justify-content: center;
			align-items: center;
			padding-left: 0 !important;
			min-width: 100%;
			margin: 0px;
		}

		#contact-map {
			max-width: 100%;
		}
	}

	h1 {
		font-size: 33px !important;
	}

	.domains .row .col-sm-6{
		min-width: 100%;
		padding: 0 15px 0 15px;
	}
	
	footer .maxillo-infos {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	footer .row #menu-bas-de-page {
		padding-left: 0 !important;
	}

	footer .info-contact{
		padding-left: 0 !important;
	}

	#footer-top {
		font-size: 15px;
	}

	#main .infos {
		font-size: 12px;
		margin-right: auto;
		margin-left: auto;
		display: flex;
	}

	.domains-slider {

		.slick-slide {
			margin: 0 10px;
		}
	}

	#page-about .row{
		display: flex;
		flex-direction: row;
		
		.offset-1 {
			min-width: 100%;
			margin: 0;
		}
		.aside {
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
		}

		.col-4{
			flex: 0 0 100%;
		}
	}

	#page-about .team .row{
		.col-6 {
			margin-left: auto;
			margin-right: auto;
		}
	}

	#page-about .partners .row{
		.col-6 {
			margin-left: auto;
			margin-right: auto;
		}
	}

	#page-about .infos {
		.row {
			display: flex;
			align-items: center !important;
			gap: 15px;
		}

		.maxillo-infos {
			max-width:100%;
			padding: 0;
		}

		.maxillo-infos .infos-contact{
			padding-right: 1rem;
			padding-left: 1rem;
		}
		.row .cta-faq {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom: 3rem;
			max-width: 100% !important;
		}

		.col-5 {
			flex: 0 0 100%;
		}
	}

	#page-contact .container .infos-contact {
		padding-right: 3rem !important;
	}

	#partenaires .logo-partners {
		gap: 10px;
		.col-6{
			padding: 15px 0 15px 0;
		}
	}

	#page-contact .form-content{
		.col-10{
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

}

@media (max-width: 576px) {
	#page-domain-single .child-pages .content {
		grid-template-columns: repeat(1, 1fr);
	}


	#page-default #gform_wrapper_3 {
		#field_3_32 #input_3_32 {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			
			img {
				height: 100%;
				max-width: 100%;
			}

			label::before {
				top: 32px !important;
			}
		}
	
		#field_3_44 #input_3_44 {
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			img {
				height: 100%;
				max-width: 100%;
			}
			label::before {
				top: 32px !important;
			}
		}
	}

	#footer {

		.bg {
			padding-bottom: 0;
			background: none;
		}

		.row {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			margin-bottom: 15px;
		}

		#logo {
			grid-column-start: 1;
			grid-column-end: 3;
			margin-right: auto;
			margin-left: auto;
			max-width: 25%;
			margin-bottom: 5px;
		}

		#footer-menu {
			grid-column-start: 1;
			grid-column-end: 2;

			@media (max-width: 530px) {
				grid-column-start: 1;
				grid-column-end: 3;
			}
		}

		.maxillo-infos {
			@media (max-width:530px) {
				grid-column-start: 1 !important;
				grid-column-end: 3;
				margin: auto;
				max-width: 92%;
			}
		}

		.offset-1 {
			grid-column-start: 2;
			grid-column-end: 3;
			max-width: 100%;
			margin-left: 0;

			ul {
				justify-content: flex-end;
			}
			.maxillo-infos .infos-contact {
				padding-left: 15px !important;
			}
		}
	}

	#page-about .infos {
		.row {
			padding-right: 15px !important;
			padding-left: 15px !important;
		}
	}

	#page-homepage .slick-slide {
		margin-left: 7.5px !important;
		margin-right: 7.5px !important;
	}

	#page-homepage {

		.offset-md-3{
			padding-left: 15px;
			padding-right: 15px;
		}

		.col-sm-6 {
			padding-left: 15px;
			padding-right: 15px;
		}
		
		.about .gallery-slider {
			margin: 0 7.5px;
			margin-top: 15px;
		}
	}

	.title {
		padding-right: 15px;
		padding-left: 15px;
		h1 {
			padding-left: 0;
		}
	}

	#button-form {
		font-size: 14px;
		padding-right: 38px;
		right: -105px;
	}



	#header  {
		#logo a img {
			margin-left: -12%;
		}

		 #header-extras {
			flex-direction: column ;
			margin-right: 9%;
			.btn-primary {
				margin-top: 2.9%;
			}
			a {
				display: none;
			}
			#language-switcher {
				margin-top: 55px;
				margin-right:23px;
				.lang-menu a{
					display: block !important;
				}
			}
		}
	}

	.introduction .row{

		.offset-2{
			margin-left: 0;
		}

		.offset-3 .btn-primary {
			float: none;
			margin-left: 0;
			display: flex;
		}
	}

	

	#page-contact .form-content h2{
		font-size: 23px;
	}


	.domains-slider {
		margin: 0 -15px;
	}
}