//
// Template Styles
// --------------------------------------------------

#main {
  padding-top: 137px; // Header height
  z-index: 15;
}

#breadcrumb {
  margin: 20px 0;

  span {
    color: $color-primary;
    font-size: 15px;

    a {
      color: $color-primary;
    }

    &.breadcrumb_last {
      color: $color-gray-darker;
      opacity: 1 !important;
    }
  }
}

.domains-slider {
  margin: 0 -20px;
  margin-bottom: 100px !important;

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  .slick-slide {
    margin: 0 20px;
  }

  .slick-dots {
    bottom: -30px;
    display: flex;
    justify-content: center;

    li {
      button {
        background-color: $color-primary !important;
        width: 10px !important;
        height: 10px !important;
      }
    }
  }

  .slick-next {
    right: -20px;

    &::before {
      color: $color-primary;
      content: "\f178";
      font-size: 20px;
      font-weight: normal;

    }
  }

  .slick-prev {
    left: -20px;

    &::before {
      color: $color-primary;
      content: "\f177";
      font-size: 20px;
      font-weight: normal;
    }
  }
}

.side-nav {
  padding: 0;
  margin: 0;
  list-style: none;

  button {
    padding: 0;
    border: none;
    border-radius: 0;
    display: inline-block;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $color-link-hover;
    }

    i {
      transition: $transition-base;
      transform: rotate(0deg);
    }
  }

  li {
    padding: 15px 10px;
    border-bottom: 3px solid #e6eef2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
      border-bottom: none;
    }

    a {
      width: calc(100% - 12px);

      &.current {
        font-weight: 600;
      }
    }

    &.is-open {

      button {

        i {
          transform: rotate(180deg);
        }
      }

      .sub-sidenav {
        display: block;
      }
    }
  }

  .sub-sidenav {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 10px;
    width: 100%;
    display: none;

    li {
      padding: 0;
      border-bottom: 1px solid #e6eef2;
      font-size: 15px;
      width: 100%;
      transition: $transition-base;

      &:last-of-type {
        border-bottom: none;
      }

      &.current {
        background-color: #e6eef2;

        a {
          font-weight: 600;
        }
      }

      &:hover {
        background-color: #e6eef2;
      }

      a {
        color: $color-text;
        width: 100%;
        padding: 15px 20px;
      }
    }
  }

  @media(max-width:767px){
    padding: 40px 0 0;
  }
}

#page-homepage {

  .domains {
    margin-top: 100px;
    margin-bottom: 100px;

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .about {
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }


    .gallery-slider {
      margin-top: 20px;

      .slick-track {
        // @media (min-width: 1632px) {
        //   max-width: ;
        // }
      }

      .img-content {
        @media (min-width: 1632px) {
          max-width: 100%;
        }
        img {
          margin: 0 auto;
        }
      }


      .slick-slide {
        margin-left: 30px;
        margin-right: 30px;
      }

      .slick-arrow {
        transform: translate(0, 0);
        top: initial;
        bottom: -50px;

      }

      .slick-next {

        padding-right: 135px;

        &::before {
          color: $color-primary;
          content: "\f178";
          font-size: 20px;
          font-weight: normal;
        }

      }

      .slick-prev {

        padding-left: 135px;

        &::before {
          color: $color-primary;
          content: "\f177";
          font-size: 20px;
          font-weight: normal;
        }
      }

      .slick-dots {
        bottom: -40px;
        display: flex;
        justify-content: center;

        button {
          width: 10px !important;
          height: 10px !important;
          background-color: $color-primary !important;
          width: 10px !important;
          height: 10px !important;
        }
      }

    }
  }




}

#page-about {

  #post-content {
    padding-top: 0;
    padding-right: 60px;
  }

  .aside {
    img {
      width: 100%;
    }
  }

  .team {
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .partners {
    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

    .logo-partners {

      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;

      a {
        overflow: hidden;

        img {
          transition: transform .5s ease;

          &:hover {
            transform: scale(1.03);
          }
        }
      }

    }

  }

  .infos {
    margin: 100px 0;

    .maxillo-infos {
      .infos-contact {
        padding-right: 40px;

      }
    }


    .cta-faq {
      background-color: rgba(10, 84, 123, 0.1);
      border-radius: 8px;

      h3 {
        margin-top: 80px;
        margin-left: 60px;
        color: $color-primary;
      }

      a {
        margin-left: 60px;

      }
    }
  }
}

.tdu-popup-inner-content {

  h2 {
    text-align: center;
  }
}

#page-contact {

  h2 {
    margin-bottom: 40px;
  }

  .maxillo-infos {
    padding-right: 0;


    .infos-contact {
      min-height: 350px;
    }
  }

  #contact-map {
    padding-left: 0;
  }

  .form-content {
    margin: 100px 0;
  }
}


#page-default {

  .title {

    h1 {
      margin-bottom: 30px !important;
    }
  }

  #field_3_8,
  #field_3_12,
  #field_3_16,
  #field_3_26,
  #field_3_38 {
    font-size: 26px;
    font-family: $font-title;
    font-weight: normal;
    line-height: 35px;
    margin-bottom: 30px;
  }

  hr {
    border: 3px solid rgba(10, 84, 123, 0.1);
    margin-bottom: 60px;
    margin-top: 60px;
  }

  #gform_wrapper_3 {

    #field_3_1 {
      .ginput_container {
        width: calc(50% - 12px);
      }
    }

    #field_3_13 {
      grid-column: span 2;

      .ginput_container {

        width: calc(50% - 12px);
      }
    }

    #field_3_12 {
      grid-column: span 2;
    }

    #field_3_17,
    #field_3_19,
    #field_3_23,
    #field_3_27,
    #field_3_32,
    #field_3_44 {
      grid-column: span 2;

      #input_3_17,
      #input_3_19,
      #input_3_23,
      #input_3_27 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .gchoice {
          width: 25%;
        }

      }

      #input_3_32,
      #input_3_44 {
        display: flex;
        flex-wrap: wrap;

        .gchoice {
          margin-right: 15px;
          width: calc(10% - 15px);

          img {
            margin: 0;
            max-width: 60px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  #input_3_39 {
    width: calc(50% - 12px);
  }

  #field_3_40 {
    grid-column: span 2;
  }
}

#page-member-single {

  .profile {
    position: relative;

    .img-content {
      position: relative;
      aspect-ratio: 1;

      &::before {
        content: "";
        background-color: rgba(10, 84, 123, 0.1);
        max-height: 310px;
        height: calc(100% - 30px);
        width: calc(100% + 30px);
        position: absolute;
        top: 15px;
        left: -15px;
        z-index: -1;
      }
    }

    .btn-primary {
      margin-bottom: 40px;
    }

    .locations {
      margin-bottom: 40px;
    }

    h6 {
      margin-bottom: 15px;
    }

    .location {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;

      i {
        color: $color-primary;
        padding-right: 10px;

      }

      span {
        color: $color-primary;
        font-size: 14px;
        line-height: 18px;
      }
    }



  }
}

#page-domains {

  h1 {
    margin-bottom: 80px;
  }
}


#page-domain-single {
  margin-bottom: 50px;

  #post-content {
    padding-top: 0px;

    img {
      margin-top: 40px;
      min-width: 30%;
    }
  }

	.accordions-domains {
    margin-top: 40px;
  }

  #post-content {
    padding-bottom: 0;
  }

  .child-pages {
    margin-top: 50px;

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
      column-gap: 20px;
    }
  }

  .videos {
    margin: 100px 0;
  }

  .video {
    aspect-ratio: 16 / 9;

    video {
      width: 100%;
      height: auto;
    }
  }

  .domains {
    margin-top: 100px;

    h2 {
      text-align: center;
    }
  }

  .btn-next {
    margin-top: 80px;
    margin-left: auto;

    i {
      margin-right: 10px;
    }
  }
}

#page-requirement-taxonomy {

  #post-content {
    margin-bottom: 100px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .domains {
    margin-top: 100px;

    h2 {
      text-align: left;
    }
  }
}

#page-gallery {

  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: 100px;

    .img-content {
      overflow: hidden;
    }
  }
}

#page-not-found {

  .page-section {

    .container {

      padding-bottom: 10%;

      .error-actions {
        display: flex;
        gap: 20px;

        a {
          gap: 10px;
        }
      }
    }
  }
}